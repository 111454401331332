:root {
  // colors
  --clr-primary-background: #25182b;
  --clr-secondary-background: #5a1f72;
  --clr-gradient-hero: linear-gradient(180deg, #9dc5ee 0%, #cc92e0 50.14%, rgba(123, 60, 148, 0) 100%);

  --clr-header-background: rgba(0, 0, 0, 0.24);

  --clr-primary-text: #ffffff;
  --clr-secondary-text: rgba(255, 255, 255, 0.6);
  --clr-accent-text: #7d23a1;

  --clr-white: #fff;
  --clr-transparent-light-bg: rgba(248, 251, 253, 0);

  --clr-action: #ad40d9;

  --clr-border-card: #d3adec;

  //boreder-radius
  --br-12: 12px;
  --br-16: 16px;
  --br-24: 24px;
  --br-32: 32px;

  //roadmap variables
  --lengthArrData: 8;
  --columns: 3;
  --rows: 3;
}
