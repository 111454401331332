@import './variable';

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;

  *::-webkit-scrollbar {
    width: 0.5em;
    border-radius: 20px;
  }

  *::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: #9dc5ee;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #7d23a1;
  }
}

body {
  height: 100dvh;
  max-width: 100dvw;
  overflow-x: hidden;
  font-size: 1rem;
  font-family: var(--font-inter), system-ui;
  background-color: var(--clr-primary-background);
  color: var(--clr-primary-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img,
svg {
  max-width: 100%;
  display: block;
}

ul {
  padding: 0;
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.container {
  width: 100%;
  max-width: 1440px;

  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;

  @media screen and (min-width: 1024px) {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  @media screen and (min-width: 1440px) {
    padding-left: 8.125rem;
    padding-right: 8.125rem;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: rgba(125, 35, 161, 0.2);
  display: none;

  @media screen and (min-width: 560px) {
    display: block;
  }

  @media screen and (min-width: 1024px) {
    top: 56%;
  }
}

.swiper-button-next {
  right: 1rem;

  @media screen and (min-width: 768px) {
    right: 2rem;
  }
}

.swiper-button-prev {
  left: 1rem;

  @media screen and (min-width: 768px) {
    left: 2rem;
  }
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: rgba(125, 35, 161, 0.4);
}

.swiper-pagination-bullet-active {
  background: rgba(125, 35, 161, 1);
  opacity: 1;
}
